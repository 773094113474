export enum Events {
  BarkPartnerPageViewed = 'Bark Partner Page Viewed',
  BarkTranslationGenerated = 'Bark Translation Generated',
  ComingSoonLanguageScreenViewed = 'Coming Soon Language Screen Viewed',
  CustomerReviewsSeen = 'Customer Reviews Seen',
  CustomerReviewCarouselClicked = 'Customer Review Carousel Clicked',
  DashboardPageVisited = 'Dashboard Page Visited',
  ErrorReportSubmitClicked = 'Error Report Submit Clicked',
  ExitIntentPopupSeen = 'Exit Intent Popup Seen',
  ExitIntentPopupDismissed = 'Exit Intent Popup Dismissed',
  ExitIntentCTASelected = 'Exit Intent Popup CTA Selected',
  ExtensionLinkSendCtaClicked = 'Extension Link Send CTA Clicked',
  ExtensionDownloadCtaClicked = 'Extension Download CTA Clicked',
  ExtensionInstalled = 'Extension Installed',
  ExtensionUninstalled = 'Extension Uninstalled',
  ExternalAuthError = 'External Auth Error',
  ErrorPageSeen = 'Error Page Seen',
  ErrorPagePrimaryCtaClicked = 'Error Page Primary CTA Clicked',
  ErrorPageContactUsClicked = 'Error Page Contact Us Clicked',
  FeatureSettingChanged = 'Feature Setting Changed',
  ForgotPasswordScreenViewed = 'Forgot Password Screen Viewed',
  ForgotPasswordSuccess = 'Forgot Password Success',
  FourOhFourPageViewed = '404 Page Viewed',
  GameCompleted = 'Game Completed',
  GameLoaded = 'Game Loaded',
  GameSpinnerViewed = 'Game Spinner Viewed',
  GooglePayCountryUnsupported = 'Google Pay country unsupported',
  GrantPermissionsScreenViewed = 'Grant Permissions Screen Viewed',
  GrantPermissionsClicked = 'Grant Permissions Clicked',
  GuidedTutorialStepViewed = 'Guided Tutorial Step Viewed',
  HomePageSectionViewed = 'Home Page Section Viewed',
  HomePageLanguageSelectionClicked = 'Home Page Language Selection Clicked',
  HomePageEmailAddedForInstallLifecycleCampaign = 'Home Page Email Added for Install Lifecycle Campaign',
  LanguageLeaderboardModalViewed = 'Language Leaderboard Modal Viewed',
  LanguageSelectionClicked = 'Language Selection Clicked',
  LanguageSelectionScreenViewed = 'Language Selection Screen Viewed',
  LearnSmarterPageViewed = 'Learn Smarter Page Viewed',
  LearnSmarterPageCTAClicked = 'Learn Smarter Page CTA Clicked',
  LoginScreenViewed = 'Login Screen Viewed',
  LoginSuccess = 'Login Success',
  MobileOnboardingLanguageSelectionScreenViewed = 'Mobile Onboarding Language Selection Screen Viewed',
  MobileOnboardingLanguageSelectionScreenScrolled = 'Mobile Onboarding Language Selection Screen Scrolled',
  MobileOnboardingTutorialScreenViewed = 'Mobile Onboarding Tutorial Screen Viewed',
  OnboardingChallengeHovered = 'Onboarding Challenge Hovered',
  OnboardingJumpOffViewed = 'Onboarding Jump-Off Viewed',
  OnboardingJumpOffClicked = 'Onboarding Jump-Off Clicked',
  OnboardingPageViewed = 'Onboarding Page Viewed',
  OnboardingReferralClicked = 'Onboarding Referral Clicked',
  OnboardingReferralModalViewed = 'Onboarding Referral Modal Viewed',
  OnboardingInteractiveTutorialStepViewed = 'Onboarding Interactive Tutorial Step Viewed',
  OnboardingSignupOptionsViewed = 'Onboarding Sign-Up Options Viewed',
  OnboardingSignupOptionClicked = 'Onboarding Sign-Up Option Clicked',
  OnboardingPermissionsScreenViewed = 'Onboarding Permissions Screen Viewed',
  OnboardingLanguageSelectionScreenViewed = 'Onboarding Language Selection Screen Viewed',
  OnboardingTargetLanguageSelectionClicked = 'Onboarding Target Language Selection Clicked',
  OnboardingLanguageSelectionSubmitted = 'Onboarding Language Selection Submitted',
  OnboardingSourceLanguageDropDownClicked = 'Onboarding Source Language Drop Down Clicked',
  OnboardingSourceLanguageOptionClicked = 'Onboarding Source Language Option Clicked',
  OnboardingTargetLanguageSelectionScreenScrolled = 'Onboarding Target Language Selection Screen Scrolled',
  OnboardingMotivationScreenViewed = 'Onboarding Motivation Screen Viewed',
  OnboardingMotivationClicked = 'Onboarding Motivation Clicked',
  OnboardingTutorialScreenViewed = 'Onboarding Tutorial Screen Viewed',
  OnboardingOverviewScreenViewed = 'Onboarding Overview Screen Viewed',
  OnboardingDataCollectionScreenViewed = 'Onboarding Data Collection Screen Viewed',
  OtherLanguageSelectedScreenViewed = 'Other Language Selected Screen Viewed',
  OtherLanguageSubmitted = 'Other Language Submitted',
  PermissionsGranted = 'Permissions Granted',
  PrivacyScreenViewed = 'Onboarding - Privacy Screen Viewed',
  ProgressReportLandingScreenDownloadClicked = 'Progress Report Landing Screen - Download Clicked',
  ProgressReportLandingScreenViewed = 'Progress Report Landing Screen Viewed',
  RedeemPageReferralRedeemed = 'Redeem Page - Referral Redeemed',
  RedeemPageViewed = 'Redeem Page Viewed',
  RegistrationScreenViewed = 'Registration Screen Viewed',
  RegistrationSuccess = 'Registration Success',
  RedirectToExtensionStoreFromMagicLink = 'Redirect To Extension Store From Magic Link',
  ReferralCtaClicked = 'Referral CTA Clicked',
  ResetPasswordScreenViewed = 'Reset Password Screen Viewed',
  RuleOfThreePageSeen = 'Rule of Three Page Seen - Instapage',
  RuleOfThreePlanSelected = 'Rule Of Three Plan Selected',
  SafariMobileInstructionsPageVisited = 'Safari Mobile Instructions Page Visited',
  SavedWordsPageViewed = 'Saved Words Page Viewed',
  ShareLanguageScreenViewed = 'Share Language Screen Viewed',
  SiteVisitedWithReferralRedeemCode = 'Site Visited with Referral Redeem Code',
  SnoozingScreenViewed = 'Snoozing Screen Viewed',
  SubscriptionCtaViewed = 'Subscription - Subscribe CTA Viewed',
  SubscriptionCtaClicked = 'Subscription - Subscribe CTA Clicked',
  SubscriptionConfirmationSurveySubmitted = 'Subscription - Confirmation Survey Submitted',
  SharedHomePageViewed = 'Shared Home Page Viewed',
  SignInClicked = 'Sign-In Clicked',
  SignUpClicked = 'Sign-Up Clicked',
  SubscriptionPageSeen = 'Subscription Page Seen',
  SubscriptionRevealPlanSectionAfterRegistration = 'Subscription - Reveal Plan Section After Registration',
  SubscriptionPlanTypeChanged = 'Subscription - Plan Type Changed',
  SubscriptionSignUpSectionSeen = 'Subscription - SignUp Section Seen',
  SubscriptionPaymentSectionSeen = 'Subscription - Payment Section Seen',
  SubscriptionSubscribeButtonClicked = 'Subscription - Subscribe Button Clicked',
  SubscriptionPaymentSuccess = 'Subscription - Subscription Payment Success',
  SubscriptionPaymentFailure = 'Subscription - Subscription Payment Failure',
  SubscriptionGetStartedButtonClicked = 'Subscription - Get Started Button Clicked',
  SubscriptionFeaturesGridSeen = 'Subscription - Features Grid Seen',
  SuccessfullyResetPassword = 'Successfully Reset Password',
  ShortcutSelectionSet = 'Shortcut Selection Set',
  ShortcutOptionsViewed = 'Shortcut Options Viewed',
  YourWordDetailsPageSubscribeCtaClicked = 'Your Word Details Page Subscribe CTA Clicked',
  YourWordDetailsPageSubscribeModalViewed = 'Your Word Details Page Subscribe Modal Viewed',
  YourWordDetailsPageCompletePurchaseCtaClicked = 'Your Word Details Page Complete Purchase CTA Clicked',
  SignUpCtaModalButtonSeen = 'Sign-Up CTA Modal Button Seen',
  SignUpCtaModalClicked = 'Sign-Up CTA Modal Clicked',
  SignUpCtaModalCompleted = 'Sign-Up CTA Modal Completed',
  DownloadExtensionPromptSeen = 'Download Extension Prompt Seen',
  DownloadExtensionPromptClicked = 'Download Extension Prompt Clicked',
}

export enum SettingValue {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
}

export enum SignUpMethod {
  Toucan = 'Toucan',
  Google = 'Google',
  Apple = 'Apple',
}

export enum SignInMethod {
  Toucan = 'Toucan',
  Google = 'Google',
  Apple = 'Apple',
}

export enum Origin {
  Onboarding = 'onboarding',
  Dashboard = 'dashboard',
}

export enum GuidedTutorialStep {
  Intro = 'intro',
  Hover = 'hover',
  Listen = 'listen',
  Save = 'save',
  IKnowThis = 'I know this',
  HighlightToTranslate = 'Highlight to translate',
  PinToucan = 'pin toucan',
  AccessSettingsSafari = 'access settings safari',
  Completion = 'completion',
}

export enum DashboardPage {
  Settings = 'settings',
  Personalize = 'personalize',
  SavedWords = 'saved words',
}

export enum RedeemPageState {
  AlreadySubscribed = 'Already Subscribed',
  PreviouslySubscribed = 'Previously Subscribed',
  KeyClaimed = 'Key Claimed',
  KeyAvailable = 'Key Available',
  CannotSelfRedeem = 'Cannot Self Redeem',
}

export enum Onboarding {
  Continue = 'continue',
  Signup = 'signup',
  Subscribe = 'subscribe',
  Skip = 'skip',
  Hover = 'hover',
  Progress = 'progress',
  Pause = 'pause',
  Confirmation = 'confirmation',
  Tap = 'tap',
  Difficulty = 'difficulty',
}
