import { useRouter } from 'next/router';
import * as qs from 'qs';

import { Events, SignUpMethod, SignInMethod } from '~/constants/events';
import { useAuthenticateUserMutation } from '~/extension/schema';
import { trackEvent, identifyUser } from '~/helpers/analytics';
import AuthHelpers from '~/helpers/auth';
import { useLoggedInUser } from '~/hooks/useLoggedInUser';
import Config from '~/lib/config';
import {
  useAuthenticateExternalLoginMutation,
  ExternalAuthProvider,
  NewUserInformationInput,
} from '~/networking/schema';

export interface EventProperties {
  registrationSuccess?: {
    isInGuidedOnboarding?: boolean;
  };
  loginSuccess?: {
    isInGuidedOnboarding?: boolean;
  };
}

const externalAuthInfo = {
  [ExternalAuthProvider.Google]: {
    redirectUri: Config.GOOGLE_AUTH_REDIRECT_URI,
    signInMethod: SignInMethod.Google,
    signUpMethod: SignUpMethod.Google,
  },
  [ExternalAuthProvider.Apple]: {
    redirectUri: Config.APPLE_AUTH_REDIRECT_URI,
    signInMethod: SignInMethod.Apple,
    signUpMethod: SignUpMethod.Apple,
  },
};

export interface PageParams {
  [key: string]: any;
}

const useExternalAuth = () => {
  const [authenticateExternalLogin] = useAuthenticateExternalLoginMutation();
  const { refreshUser } = useLoggedInUser();
  const [authenticateUser] = useAuthenticateUserMutation();
  const router = useRouter();

  const signInWithGoogle = ({
    pagePath,
    pageParams,
    eventProperties,
  }: {
    pagePath: string;
    pageParams?: PageParams;
    eventProperties?: EventProperties;
  }) => {
    const params = {
      client_id: Config.TOUCAN_GOOGLE_AUTH_CLIENT_ID,
      redirect_uri: externalAuthInfo[ExternalAuthProvider.Google].redirectUri,
      scope: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
        'openid',
      ].join(' '),
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
      state: qs.stringify({
        pagePath,
        pageParams,
        eventProperties,
      }),
    };

    const loginUrl = `https://accounts.google.com/o/oauth2/v2/auth?${qs.stringify(params)}`;
    window.open(loginUrl, '_self');
  };

  const signInWithApple = ({
    pagePath,
    pageParams,
    eventProperties,
  }: {
    pagePath: string;
    pageParams?: PageParams;
    eventProperties?: EventProperties;
  }) => {
    const params = {
      client_id: Config.TOUCAN_APPLE_AUTH_CLIENT_ID,
      redirect_uri: externalAuthInfo[ExternalAuthProvider.Apple].redirectUri,
      scope: 'name email',
      response_type: 'code',
      response_mode: 'form_post',
      state: qs.stringify({
        pagePath,
        pageParams,
        eventProperties,
      }),
    };

    const loginUrl = `https://appleid.apple.com/auth/authorize?${qs.stringify(params)}`;
    window.open(loginUrl, '_self');
  };

  const handleExternalAuth = async ({
    code,
    internalRedirectPath,
    internalRedirectParams,
    eventProperties,
    authProvider,
    isAuthFromExtension,
    newUserInformation,
  }: {
    code: string;
    internalRedirectPath?: string;
    internalRedirectParams?: PageParams;
    eventProperties?: EventProperties;
    authProvider: ExternalAuthProvider;
    isAuthFromExtension?: boolean;
    newUserInformation?: NewUserInformationInput;
  }) => {
    let jwt: string | undefined;
    let refreshToken: string | undefined;
    let userId: string | undefined;
    let isNewUser = false;

    // Validate the code that came from the query parameter
    try {
      const result = await authenticateExternalLogin({
        variables: {
          input: {
            code,
            redirectUrl: externalAuthInfo[authProvider].redirectUri,
            authProvider,
            newUserInformation,
          },
        },
      });
      jwt = result?.data?.authenticateExternalLogin?.jwt;
      refreshToken = result?.data?.authenticateExternalLogin?.refreshToken;
      userId = result?.data?.authenticateExternalLogin?.id;
      isNewUser = result?.data?.authenticateExternalLogin?.isNewUser ?? false;
    } catch (e) {
      // Ignore and continue
    }

    if (!jwt || !refreshToken || !userId) {
      // Auth the extension user
      try {
        await authenticateUser({
          input: {
            token: jwt,
            refreshToken,
          },
        });
      } catch (e) {
        // Ignore its possible the extension is not installed
      }
      return;
    }

    // Save the new auth tokens
    AuthHelpers.setTokenCookie(jwt);
    AuthHelpers.setRefreshTokenCookie(refreshToken);

    // Determine if this user is signing in or signing up and fire appropriate event
    if (isNewUser) {
      trackEvent({
        name: Events.RegistrationSuccess,
        properties: {
          signUpMethod: externalAuthInfo[authProvider].signUpMethod,
          ...eventProperties?.registrationSuccess,
        },
      });
    } else {
      trackEvent({
        name: Events.LoginSuccess,
        properties: {
          SignInMethod: externalAuthInfo[authProvider].signInMethod,
          ...eventProperties?.loginSuccess,
        },
      });
    }

    // Authenticate the user in the extension
    try {
      await authenticateUser({
        input: {
          token: jwt,
          refreshToken,
        },
      });
    } catch (e) {
      // Ignore its possible the extension is not installed
    }

    if (isAuthFromExtension) {
      // Close out this window because the cookies have been set, and allow the user to get back to browsing
      window.close();
      return;
    }

    await refreshUser();
    identifyUser(userId);

    if (internalRedirectPath) {
      // Force hard load on the subscribe page to ensure the geolocation API is called on the server
      if (internalRedirectPath === '/subscribe') {
        window.open(`/subscribe?${qs.stringify(internalRedirectParams)}`, '_self');
        return;
      }

      const redirectPath = `${internalRedirectPath}?${qs.stringify({
        fromExternalAuth: true,
        ...internalRedirectParams,
      })}`;

      router.push(redirectPath);
    }
  };

  const handleExternalAuthError = async ({
    error,
    internalRedirectParams,
    internalRedirectPath,
    isAuthFromExtension,
    authProvider,
  }: {
    error?: string;
    internalRedirectPath?: string;
    internalRedirectParams?: PageParams;
    isAuthFromExtension?: boolean;
    authProvider: ExternalAuthProvider;
  }) => {
    trackEvent({
      name: Events.ExternalAuthError,
      properties: {
        error,
        redirect: internalRedirectPath,
        authProvider,
      },
    });

    if (isAuthFromExtension) {
      try {
        // Tell the extension that the auth failed
        await authenticateUser({
          input: {
            errorCode: error,
          },
        });
      } catch (e) {
        // Ignore its possible the extension is not installed
      }
    }

    // Redirect even on error.
    if (internalRedirectPath) {
      // Force hard load on the subscribe page to ensure the geolocation API is called on the server
      if (internalRedirectPath === '/subscribe') {
        window.open(`/subscribe?${qs.stringify(internalRedirectParams)}`, '_self');
        return;
      }

      const redirectPath = `${internalRedirectPath}?${qs.stringify({
        fromExternalAuth: true,
        ...internalRedirectParams,
      })}`;

      router.push(redirectPath);
    }
  };

  return {
    signInWithGoogle,
    signInWithApple,
    handleExternalAuth,
    handleExternalAuthError,
  };
};

export default useExternalAuth;
